import { RacwaAuth } from "@racwa/react-adb2c";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App as AppContent } from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ADB2C_CLIENT_ID, ADB2C_DOMAIN, ADB2C_TENANT } from "./shared/constants";

let App: any;
const racwaAuthProps = {
  domain: ADB2C_DOMAIN,
  tenant: ADB2C_TENANT,
  clientId: ADB2C_CLIENT_ID,
  strict: true,
};

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const {
    BypassService,
    FeatureToggleProvider,
    ShieldEnvironmentProvider,
    STORAGE_KEY_NPE_RACWA_CRM_ID,
    RacwaInternalAuth,
  } = require("raci-react-library-npe");

  const featureToggles = JSON.parse(process.env.REACT_APP_FEATURE_TOGGLES ?? "[]");
  const environments = JSON.parse(process.env.REACT_APP_SHIELD_ENVIRONMENTS ?? "[]");

  BypassService.setCrmIdFromQuerystring();
  BypassService.setShieldEnvironmentFromQuerystring();
  BypassService.setOverrideToNumberFromQuerystring();
  BypassService.setFeatureToggleFromQuerystring();
  BypassService.setSourceUrlFromQuerystring();

  // If the bypass CRM ID is set, use the App without the MyRAC auth component
  const AppInternal = sessionStorage.getItem(STORAGE_KEY_NPE_RACWA_CRM_ID)
    ? () => <AppContent />
    : () => (
        <RacwaAuth {...racwaAuthProps}>
          <AppContent />
        </RacwaAuth>
      );

  const NpeApp = () => (
    <FeatureToggleProvider featureToggles={featureToggles}>
      <ShieldEnvironmentProvider environments={environments}>
        <AppInternal />
      </ShieldEnvironmentProvider>
    </FeatureToggleProvider>
  );

  if (process.env.REACT_APP_ENVIRONMENT !== "UAT") {
    const { NPE_OAUTH_CLIENT_ID } = require("./__NPE__/constants");
    const { NPE_OAUTH_AUTHORITY } = require("./__NPE__/constants");
    App = () => (
      <RacwaInternalAuth
        clientId={NPE_OAUTH_CLIENT_ID}
        authority={NPE_OAUTH_AUTHORITY}
        redirectUri={process.env.REACT_APP_ENVIRONMENT === "LOCAL" ? window.location.origin : process.env.PUBLIC_URL}
      >
        <NpeApp />
      </RacwaInternalAuth>
    );
  } else {
    App = () => <NpeApp />;
  }
} else {
  App = require("./App").default;
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />,
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
